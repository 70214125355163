import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p className="mb-5">
                    Una de les dificultats presents per qui desitgi accedir a qualsevol Policia
                    Local catalana és l'absència d'un temari comú que l'ajudi a preparar-se
                    l'oposició.
                </p>

                <p className="mb-5">
                    Tenint en compte aquesta circumstància, hem creat aquest llibre, que recull els
                    temes més sol·licitats a les diverses oposicions de Policia Local de Catalunya,
                    amb l'objectiu de facilitar-vos-en l'accés. Triar les eines per preparar-se una
                    oposició és una opció personal.
                </p>

                <p>
                    Nosaltres, des de l'experiència i aplicant les tècniques d'estudi més eficaces,
                    hem creat una <strong>bona eina</strong>, i la volem compartir amb tu.
                </p>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/318/promo.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>

                <p className="mt-5">
                    Si no pots previsualitzar correctament la prèvia pots fer{" "}
                    <a
                        rel="noopener noreferrer"
                        href={require("@static/documents/products/318/preview.pdf")}
                        target="_blank"
                        className="default-link"
                    >
                        clic
                    </a>{" "}
                    aquí.
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't amb el millor recurs per accedir a la Policia Local de Catalunya: un llibre creat des de l'experiència, dissenyat per ajudar-te a aconseguir la teva plaça amb confiança i eficàcia!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
